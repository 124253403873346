import Default from '../layouts/Default';
import React from 'react';

const Policy: React.FC = () => {
  return (
    <Default>
      <div className="inner-page">
        <div className="section text-page">
          <h2>Политика конфиденциальности</h2>

          <h3>1. Общие условия использования Сервиса.</h3>
          <p>Политика обработки персональных данных (далее — Политика) разработана в соответствии с Федеральным законом от
            27.07.2006. No 152-ФЗ «О персональных данных» (далее — ФЗ-152). Настоящая Политика определяет порядок обработки
            персональных данных и меры по обеспечению безопасности персональных данных Индивидуального предпринимателя Егорова
            Александра Андреевича (далее — Оператор) с целью защиты прав и свобод человека и гражданина при обработке его
            персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.</p>
          <h3>В Политике используются следующие основные понятия:</h3>

          <p>Автоматизированная обработка персональных данных - обработка персональных данных с помощью средств вычислительной
            техники;</p>
          <p>Блокирование персональных данных - временное прекращение обработки персональных данных (за исключением случаев, если
            обработка необходима для уточнения персональных данных);</p>

          <p>Обезличивание персональных данных - действия, в результате которых невозможно определить без использования
            дополнительной информации принадлежность персональных данных конкретному субъекту персональных данных;</p>

          <p>Обработка персональных данных - любое действие (операция) или совокупность действий (операций), совершаемых с
            использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор,
            запись,
            систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу
            (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;</p>

          <p>Пользователь - любой посетитель веб-сайта https://well-mind.ru/. Оператор - государственный орган, муниципальный
            орган,
            юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие
            обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных,
            подлежащих обработке, действия (операции), совершаемые с персональными данными; Персональные данные - любая
            информация,
            относящаяся к прямо или косвенно определенному или определяемому Пользователю веб-сайта https://well-mind.ru/
            (субъекту
            персональных данных);</p>

          <p>Предоставление персональных данных - действия, направленные на раскрытие персональных данных определенному лицу или
            определенному кругу лиц; Распространение персональных данных - действия, направленные на раскрытие персональных данных
            неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного
            круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в
            информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным
            способом;</p>
          <p>Трансграничная передача персональных данных - передача персональных данных на территорию иностранного государства
            органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу;</p>

          <p>Уничтожение персональных данных - действия, в результате которых невозможно восстановить содержание персональных
            данных
            в информационной системе персональных данных и (или) результате которых уничтожаются материальные носители
            персональных
            данных.</p>

          <h3>2. Принципы и условия обработки персональных данных</h3>

          <p>Обработка персональных данных у Оператора осуществляется на основе следующих принципов:</p>

          <p>
            Законности и справедливой основы;<br/>
            Ограничения обработки персональных данных достижением конкретных, заранее определенных и законных целей;<br/>
            Недопущения обработки персональных данных, несовместимой с целями сбора персональных данных;<br/>
            Недопущения объединения баз данных, содержащих персональные данные, обработка которых осуществляется в целях,
            несовместимых между собой;<br/>
            Обработки только тех персональных данных, которые отвечают целям их обработки;<br/>
            Соответствия содержания и объема обрабатываемых персональных данных заявленным целям обработки;<br/>
            Недопущения обработки персональных данных, избыточных по отношению к заявленным целям их обработки;<br/>
            Обеспечения точности, достаточности и актуальности персональных данных по отношению к целям обработки персональных
            данных;<br/>
            Уничтожения либо обезличивания персональных данных по достижении целей их обработки или в случае утраты необходимости
            в
            достижении этих целей, при невозможности устранения Оператором допущенных нарушений персональных данных, если иное не
            предусмотрено федеральным законом.
          </p>

          <p>
            2.2. Условия обработки персональных данных<br/>
            Оператор производит обработку персональных данных при наличии хотя бы одного из следующих условий:
          </p>

          <p>
            Обработка персональных данных осуществляется с согласия субъекта персональных данных на обработку его персональных
            данных;<br/>
            Обработка персональных данных необходима для достижения целей, предусмотренных международным договором Российской
            Федерации или законом, для осуществления и выполнения возложенных законодательством Российской Федерации на оператора
            функций, полномочий и обязанностей;<br/>
            Обработка персональных данных необходима для осуществления правосудия, исполнения судебного акта, акта другого органа
            или должностного лица, подлежащих исполнению в соответствии с законодательством Российской Федерации об исполнительном
            производстве; — обработка персональных данных необходима для исполнения договора, стороной которого либо
            выгодоприобретателем или поручителем по которому является субъект персональных данных, а также для заключения договора
            по инициативе субъекта персональных данных или договора, по которому субъект персональных данных будет являться
            выгодоприобретателем или поручителем;<br/>
            Обработка персональных данных необходима для исполнения договора, стороной которого либо выгодоприобретателем или
            поручителем по которому является субъект персональных данных, а также для заключения договора по инициативе субъекта
            персональных данных или договора, по которому субъект персональных данных будет являться выгодоприобретателем или
            поручителем;<br/>
            Обработка персональных данных необходима для осуществления прав и законных интересов оператора или третьих лиц либо
            для
            достижения общественно значимых целей при условии, что при этом не нарушаются права и свободы субъекта персональных
            данных;<br/>
            Осуществляется обработка персональных данных, доступ неограниченного круга лиц к которым предоставлен субъектом
            персональных данных либо по его просьбе (далее — общедоступные персональные данные);<br/>
            Осуществляется обработка персональных данных, подлежащих опубликованию или обязательному раскрытию в соответствии с
            федеральным законом.
          </p>

          <p>
            2.3. Конфиденциальность персональных данных<br/>
            Оператор и иные лица, получившие доступ к персональным данным, обязаны не раскрывать третьим лицам и не распространять
            персональные данные без согласия субъекта персональных данных, если иное не предусмотрено федеральным законом.
          </p>

          <p>
            2.4. Общедоступные источники персональных данных<br/>
            В целях информационного обеспечения у Оператора могут создаваться общедоступные источники персональных данных
            субъектов,
            в том числе справочники и адресные книги. В общедоступные источники персональных данных с письменного согласия
            субъекта
            могут включаться его фамилия, имя, отчество, дата и место рождения, номера контактных телефонов, адрес электронной
            почты
            и иные персональные данные, сообщаемые субъектом персональных данных. Сведения о субъекте должны быть в любое время
            исключены из общедоступных источников персональных данных по требованию субъекта либо по решению суда или иных
            уполномоченных государственных органов.
          </p>

          <p>
            2.5. Специальные категории персональных данных<br/>
            Обработка Оператором специальных категорий персональных данных, касающихся расовой, национальной принадлежности,
            политических взглядов, религиозных или философских убеждений, состояния здоровья, интимной жизни, допускается в
            случаях,
            если:
          </p>

          <p>
            Cубъект персональных данных дал согласие в письменной форме на обработку своих персональных данных;<br/>
            Персональные данные сделаны общедоступными субъектом персональных данных;<br/>
            Обработка персональных данных осуществляется в соответствии с законодательством о государственной социальной помощи,
            трудовым законодательством, законодательством Российской Федерации о пенсиях по государственному пенсионному
            обеспечению, о трудовых пенсиях;<br/>
            Обработка персональных данных осуществляется в медико-профилактических целях, в целях установления медицинского
            диагноза, оказания медицинских и медико-социальных услуг при условии, что обработка персональных данных осуществляется
            лицом, профессионально занимающимся медицинской деятельностью и обязанным в соответствии с законодательством
            Российской
            Федерации сохранять врачебную тайну;<br/>
            Обработка персональных данных необходима для установления или осуществления прав субъекта персональных данных или
            третьих лиц, а равно и в связи с осуществлением правосудия;<br/>
            Обработка персональных данных осуществляется в соответствии с законодательством об обязательных видах страхования, со
            страховым законодательством. <br/>
            Обработка специальных категорий персональных данных должна быть незамедлительно прекращена, если устранены причины,
            вследствие которых осуществлялась их обработка, если иное не установлено федеральным законом.
          </p>

          <p>
            2.6. Поручение обработки персональных данных другому лицу <br/>
            Оператор вправе поручить обработку персональных данных другому лицу с согласия субъекта персональных данных, если иное
            не предусмотрено федеральным законом, на основании заключаемого с этим лицом договора. Лицо, осуществляющее обработку
            персональных данных по поручению Оператора, обязано соблюдать принципы и правила обработки персональных данных,
            предусмотренные ФЗ-152.
          </p>

          <p>
            2.7. Трансграничная передача персональных данных <br/>
            Оператор обязан убедиться в том, что иностранным государством, на территорию которого предполагается осуществлять
            передачу персональных данных, обеспечивается адекватная защита прав субъектов персональных данных, до начала
            осуществления такой передачи. Трансграничная передача персональных данных на территории иностранных государств, не
            обеспечивающих адекватной защиты прав субъектов персональных данных, может осуществляться в случаях:
          </p>

          <p>
            Наличия согласия в письменной форме субъекта персональных данных на трансграничную передачу его персональных
            данных;<br/>
            Исполнения договора, стороной которого является субъект персональных данных.
          </p>

          <h3>3. Права субъекта персональных данных</h3>

          <p>
            3.1. Согласие субъекта персональных данных на обработку его персональных данных <br/>
            Субъект персональных данных принимает решение о предоставлении его персональных данных и дает согласие на их обработку
            свободно, своей волей и в своем интересе. Согласие на обработку персональных данных может быть дано субъектом
            персональных данных или его представителем в любой позволяющей подтвердить факт его получения форме, если иное не
            установлено федеральным законом. Обязанность предоставить доказательство получения согласия субъекта персональных
            данных
            на обработку его персональных данных или доказательство наличия оснований, указанных в ФЗ-152, возлагается на
            Оператора.
          </p>

          <p>
            3.2. Права субъекта персональных данных <br/>
            Субъект персональных данных имеет право на получение у Оператора информации, касающейся обработки его персональных
            данных, если такое право не ограничено в соответствии с федеральными законами. Субъект персональных данных вправе
            требовать от Оператора уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные
            данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной
            цели обработки, а также принимать предусмотренные законом меры по защите своих прав.
          </p>

          <p>Запрещается принятие на основании исключительно автоматизированной обработки персональных данных решений, порождающих
            юридические последствия в отношении субъекта персональных данных или иным образом затрагивающих его права и законные
            интересы, за исключением случаев, предусмотренных федеральными законами, или при наличии согласия в письменной форме
            субъекта персональных данных.</p>

          <p>Если субъект персональных данных считает, что Оператор осуществляет обработку его персональных данных с нарушением
            требований ФЗ-152 или иным образом нарушает его права и свободы, субъект персональных данных вправе обжаловать
            действия
            или бездействие Оператора в Уполномоченный орган по защите прав субъектов персональных данных или в судебном
            порядке.</p>

          <p>Субъект персональных данных имеет право на защиту своих прав и законных интересов, в том числе на возмещение убытков
            и
            (или) компенсацию морального вреда в судебном порядке.</p>

          <h3>4. Обеспечение безопасности персональных данных</h3>

          <p>Безопасность персональных данных, обрабатываемых Оператора, обеспечивается реализацией правовых, организационных и
            технических мер, необходимых для обеспечения требований федерального законодательства в области защиты персональных
            данных.</p>

          <p>Для предотвращения несанкционированного доступа к персональным данным Оператором применяются следующие
            организационно-технические меры:</p>

          <p>
            Ограничение состава лиц, имеющих доступ к персональным данным;<br/>
            Ознакомление субъектов с требованиями федерального законодательства и нормативных документов Оператора по обработке и
            защите персональных данных;<br/>
            Организация учета, хранения и обращения носителей информации;<br/>
            Проверка готовности и эффективности использования средств защиты информации;<br/>
            Разграничение доступа пользователей к информационным ресурсам и программно-аппаратным средствам обработки
            информации;<br/>
            Использование антивирусных средств и средств восстановления системы защиты персональных данных.
          </p>

          <h3>5. Заключительные положения</h3>

          <p>Иные права и обязанности Оператора, как оператора персональных данных определяются законодательством Российской
            Федерации в области персональных данных.</p>

          <p>
            <b>Соглашение об обработке персональных данных</b><br/>
            Данное соглашение об обработке персональных данных разработано в соответствии с законодательством Российской
            Федерации.
          </p>

          <p>Присоединяясь к настоящему Соглашению и оставляя свои данные на Сайте https://well-mind.ru/ (далее – Сайт), путем
            заполнения полей онлайн-форм (регистрация, форма обратной связи и пр.) Пользователь выражает свое Согласие на
            обработку
            персональных данных и их передачу – ИП Егорову Александру Андреевичу (ИНН 773323956498, ОГРНИП 319774600117874) (далее
            –
            Администрация Сайта), на следующих условиях.</p>

          <p>Пользователь:</p>

          <p>
            Подтверждает, что все указанные им данные принадлежат лично ему;<br/>
            Подтверждает и признает, что им внимательно в полном объеме прочитано Соглашение и условия обработки его персональных
            данных, указываемых им в полях онлайн-форм (регистрация, форма обратной связи и пр.), текст Соглашения и 4условия
            обработки персональных данных ему понятны;<br/>
            Выражает Согласие на обработку персональных данных без оговорок и ограничений (далее – Согласие). Моментом принятия
            Согласия является маркировка соответствующего поля в онлайн-форме и нажатие на кнопку отправки онлайн-формы на любой
            странице Сайта;<br/>
            Подтверждает, что, давая Согласие, он действует свободно, своей волей и в своем интересе;<br/>
            Данное Согласие дается на обработку персональных данных как без использования средств автоматизации, так и с их
            использованием. Пользователь дает свое согласие на обработку его персональных данных, а именно совершение действий,
            предусмотренных п. 3 ч. 1 ст. 3 Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных», и подтверждает,
            что,
            давая такое согласие, он действует свободно, своей волей и в своем интересе. <br/>
            Согласие Пользователя на обработку персональных данных является конкретным, информированным и сознательным.
          </p>

          <p>Согласие дается на обработку следующих персональных данных Пользователя, указанных Пользователем в формах или в
            файлах,
            прикрепленных к формам:</p>

          <p>
            Фамилия, имя, отчество;<br/>
            Телефон;<br/>
            Адрес электронной почты;
          </p>

          <p>
            Иных персональных данных, указанных Пользователем в формах или файлах, прикрепленных к формам. <br/>
            Целью обработки персональных данных является их хранение и использование, в том числе:
          </p>

          <p>
            Предоставление доступа Пользователю к Сервису;<br/>
            Ответы на запросы Пользователей;<br/>
            Информирование Пользователя посредством отправки электронных писем и смс-уведомлений, в том числе рекламного
            содержания,
            информационных и новостных рассылок;<br/>
            Заключение, исполнение и прекращение гражданско-правовых договоров с Пользователем;
          </p>

          <p>Пользователь, принимая условия настоящего Соглашения, выражает свою заинтересованность и дает полное согласие, что
            обработка его персональных данных включает в себя следующие действия: сбор, запись, систематизацию, накопление,
            хранение, уточнение (обновление, изменение), извлечение, использование, передачу (предоставление доступа),
            обезличивание, блокирование, удаление, уничтожение персональных данных.</p>

          <p>Настоящее Согласие Пользователя признается исполненным в простой письменной форме. Согласие действует бессрочно с
            момента предоставления персональных данных Пользователем Администрации Сайта и может быть отозвано Вами путем подачи
            соответствующего заявления в свободной форме Администрации Сайта.</p>

          <p>Отзыв согласия на обработку персональных данных, предоставление информации об уточнении данных, запросы об их полном
            или частичном удалении могут быть осуществлены путем направления Пользователем соответствующего распоряжения в простой
            письменной форме на адрес электронной почты: info@well-mind.ru.</p>

          <p>В случае отзыва Пользователем согласия на обработку персональных данных Администрация Сайта вправе продолжить
            обработку
            персональных данных без согласия Пользователя при наличии оснований, указанных в пунктах 2-11 части 1 статьи 6, части
            2
            статьи 10 и части 2 статьи 11 Федерального закона №152-ФЗ «О персональных данных».</p>

          <p>Передача персональных данных Пользователя третьим лицам не осуществляется, за исключением лиц, осуществляющих
            обработку
            персональных данных по поручению Администрации Сайта и от его имени, а также случаев, установленных законодательством.
            Администрация Сайта имеет право вносить изменения в настоящее Соглашение в любое время. Новая редакция Соглашения
            вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Соглашения. Действующая редакция
            всегда находится на странице по адресу: www.well-mind.ru</p>

        </div>
      </div>
    </Default>
  );
}

export default Policy;